<template>
	<v-app class="grey lighten-3">
		<v-main>
			<v-container no-gutters fill-height fluid class="py-0">
				<v-row style="height: 100%;">
					<v-col sm="12" md="7" cols="12" class="pa-0" v-if="$vuetify.breakpoint.mdAndUp">
						<v-img :src="image" :gradient="'to top, rgba(80,80,80,.6), rgba(20,20,20,.8)'" height="100%">
							<v-card flat class="pa-12 transparent">
								<v-card-title class="text-h4 white--text">Bem vindo ao FAC!</v-card-title>
							</v-card>
						</v-img>
					</v-col>
					<v-col sm="12" md="5" cols="12" :class="$vuetify.breakpoint.smAndDown ? 'pa-0' : 'pa-4'">
						<!-- MOBILE RENDERING WITH IMAGE -->
						<v-img v-if="$vuetify.breakpoint.smAndDown" :src="image" :gradient="'to top, rgba(80,80,80,.6), rgba(20,20,20,.8)'" height="100%">
							<v-container no-gutters fill-height fluid>
								<v-row align="center" justify="center">
									<v-col sm="12" md="12" cols="12">
										<v-row no-gutters>
											<v-col sm="12" md="12" cols="12" :class="$vuetify.breakpoint.smAndDown ? 'pa-2' : 'pa-4'">
												<v-alert border="left" colored-border type="error" elevation="2" dismissible :value="error" class="animate__animated animate__flash">
													Usuário ou senha inválidos
												</v-alert>
												<v-card-title class="pl-0 primary--text text--lighten-3 font-weight-regular" v-if="$vuetify.breakpoint.smAndDown">{{ 'Bem vindo ao FAC!' }}</v-card-title>
												<v-form @submit.prevent="doLogin" ref="loginForm">
													<v-row>
														<v-col cols="12">
															<p class="mb-0 subtitle-2 grey--text text--lighten-3">Login</p>
														</v-col>
														<v-col cols="12">
															<v-text-field :disabled="loading" outlined dense hide-details dark :label="'Email'" :placeholder="'Email'" @keydown.space.prevent v-model.trim="login.email" :prepend-inner-icon="'mdi-email'" :rules="[$rules.required, $rules.email]"></v-text-field>
														</v-col>
														<v-col cols="12">
															<v-text-field :disabled="loading" outlined dense hide-details dark :label="'Senha'" :placeholder="'Senha'" :prepend-inner-icon="'mdi-lock'" :append-icon="login.showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="login.showPassword ? 'text' : 'password'" @click:append="login.showPassword = !login.showPassword" v-model="login.password" :rules="[$rules.required]"></v-text-field>
														</v-col>
													</v-row>

													<v-card flat class="transparent mt-3">
														<v-card-actions class="pa-0">
															<v-spacer></v-spacer>
															<v-btn depressed class="px-5" color="success" @click="doRegister">{{ 'Não Tem Cadastro?' }}</v-btn>
															<v-btn depressed class="px-5" color="primary" type="submit" :loading="loading">{{ 'Entrar' }}</v-btn>
														</v-card-actions>
														<p class="text-right my-0 mt-2"><a @click="forgotPassword()" class="grey--text text--lighten-3">Esqueceu sua senha?</a></p>
													</v-card>
												</v-form>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-container>
						</v-img>

						<!-- DESKTOP RENDERING -->
						<v-container no-gutters fill-height fluid v-if="!$vuetify.breakpoint.smAndDown">
							<v-row align="center" justify="center">
								<v-col sm="12" md="12" cols="12">
									<v-row no-gutters>
										<v-col sm="12" md="12" cols="12" :class="$vuetify.breakpoint.smAndDown ? 'pa-2' : 'pa-4'">
											<v-alert border="left" colored-border type="error" elevation="2" dismissible :value="error" class="animate__animated animate__flash">
												Usuário ou senha inválidos
											</v-alert>
											<v-card-title class="pl-0 primary--text font-weight-regular" v-if="$vuetify.breakpoint.smAndDown">{{ 'Bem vindo ao FAC!' }}</v-card-title>
											<v-form @submit.prevent="doLogin" ref="loginForm">
												<v-row>
													<v-col cols="12">
														<p class="mb-0 subtitle-2">Login</p>
													</v-col>
													<v-col cols="12">
														<v-text-field :disabled="loading" outlined dense hide-details :label="'Email'" :placeholder="'Email'" @keydown.space.prevent v-model.trim="login.email" :prepend-inner-icon="'mdi-email'" :rules="[$rules.required, $rules.email]"></v-text-field>
													</v-col>
													<v-col cols="12">
														<v-text-field :disabled="loading" outlined dense hide-details :label="'Senha'" :placeholder="'Senha'" :prepend-inner-icon="'mdi-lock'" :append-icon="login.showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="login.showPassword ? 'text' : 'password'" @click:append="login.showPassword = !login.showPassword" v-model="login.password" :rules="[$rules.required]"></v-text-field>
													</v-col>
												</v-row>

												<v-card flat class="transparent mt-3">
													<v-card-actions class="pa-0">
														<v-spacer></v-spacer>
														<v-btn depressed class="px-5" color="primary" @click="doRegister">{{ 'Não Tem Cadastro?' }}</v-btn>
														<v-btn depressed class="px-5" color="success" type="submit" :loading="loading">{{ 'Entrar' }}</v-btn>
													</v-card-actions>
													<p class="text-right my-0 mt-2"><a @click="forgotPassword()">Esqueceu sua senha?</a></p>
												</v-card>
											</v-form>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-container>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import "../assets/css/animate.min.css"
import bgImage from '../assets/img/fac-img2.jpg'

export default {
	name: 'Login',
	data() {
		return {
			loading: false,
			error: false,
			image: bgImage,
			login: {
				email: '',
				password: '',
				showPassword: false
			}
		}
	},
	beforeMount() {
		this.$store.dispatch('checkAuth').then(auth => {
			if (auth && auth.content && auth.content.id) {
				this.$router.push('/inicio')
			}
		})
	},
	methods: {
		doLogin() {
			this.error = false

			// TRIM FIRST
			if (this.login.email) {
				this.login.email = this.login.email.trim()
			}

			// VALIDATE
			this.$nextTick(() => {
				if (this.$refs.loginForm.validate()) {
					this.loading = true
					this.$store.dispatch('doLogin', {
						email: this.login.email,
						password: this.login.password,
					}).then(result => {
						this.loading = false
						this.$router.push('/inicio')
					}).catch(error => {
						this.loading = false
						if (error && error.status && (error.status === 403 || error.status === 500)) {
							this.error = true
						}
					})
				}
				else {
					this.error = true
				}
			})
		},
		doRegister() {
			this.$router.push('/registrar')
		},
		forgotPassword() {
			this.$router.push('/redefinicao')
		}
	}
}
</script>

<style scoped>
body {
	background-color: grey;
}
</style>
